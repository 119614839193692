.app {
  width: 100vw;
  height: 100vh;
  background-image: url('../public/images/bg.png');
  background-size: cover;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app img {
  width: 250px;
  height: 250px;
}